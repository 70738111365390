import React from "react";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import Footer from "../components/Footer/Footer";
import Topbar from "../components/Topbar/Topbar";
import PostListing from "../components/PostListing/PostListing";
import { HomeSEO } from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import Layout from "../components/layout";

class Index extends React.Component {
  render() {
    const posts = this.props.data.posts.edges;
    const categories = this.props.data.categories.edges;
    return (
      <Layout>
        <div className="index-container">
          <Helmet title={config.siteTitle}>
            <meta name="theme-color" content={config.themes.default.main} />
          </Helmet>
          <HomeSEO />
          <Topbar categories={categories} shareTitle={config.siteTitleAlt} />
          <h1 className="ninja">
            {config.siteTitle}
          </h1>
          <PostListing big posts={posts} />
          <Footer />
        </div>
      </Layout>
    );
  }
}

export default Index;

/* eslint no-undef: "off" */
export const pageQuery = graphql`
  query IndexQuery {
    posts: allCrossCastPost(
      limit: 2000
      sort: { fields: [datePublished], order: DESC }
    ) {
      edges {
        node {
          slug
          coverId
          coverAlt
          coverTitle
          title
          category {
            slug
            title
          }
          abstract
        }
      }
    }
    categories: allCrossCastCategory(
      filter: { parentCategory: { slug: { eq: null } } }
    ) {
      edges {
        node {
          slug
          title
          position
          subcategories {
            slug
            title
          }
        }
      }
    }
  }
`;
