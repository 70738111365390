import React from "react";
import Grid from "../Grid/Grid";
import Tile, { BigTile } from "../Tile/Tile";

class PostListing extends React.Component {
  constructor() {
    super();
    const postsToShow = 13;
    this.state = {
      postsToShow
    };
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll);
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll);
  }

  get postList() {
    return this.props.posts.map(({ node }) => ({
      slug: node.slug,
      coverId: node.coverId,
      coverAlt: node.coverAlt,
      coverTitle: node.coverTitle,
      title: node.title,
      category: node.category,
      abstract: node.abstract
    }));
  }

  update() {
    const distanceToBottom =
      document.body.offsetHeight - (window.scrollY + window.innerHeight);

    if (distanceToBottom < 200) {
      this.setState({ postsToShow: this.state.postsToShow + 12 });
    }

    this.ticking = false;
  }

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true;
      requestAnimationFrame(() => this.update());
    }
  };

  render() {
    return (
      <Grid>
        <h2 className="ninja">Gli ultimi post</h2>
        {this.postList
          .slice(0, this.state.postsToShow)
          .map(
            (post, i) =>
              i === 0 && this.props.big ? (
                <BigTile
                  key={post.slug}
                  slug={post.slug}
                  categorySlug={post.category.slug}
                  coverId={post.coverId}
                  coverAlt={post.coverAlt}
                  coverTitle={post.coverTitle}
                  category={post.category.title}
                  title={post.title}
                  abstract={post.abstract}
                />
              ) : (
                <Tile
                  key={post.slug}
                  slug={post.slug}
                  categorySlug={post.category.slug}
                  coverId={post.coverId}
                  coverAlt={post.coverAlt}
                  coverTitle={post.coverTitle}
                  category={post.category.title}
                  title={post.title}
                  abstract={post.abstract}
                />
              )
          )}
      </Grid>
    );
  }
}

export default PostListing;
