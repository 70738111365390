import React, { Component } from "react";
import Helmet from "react-helmet";
import config from "../../../data/SiteConfig";
import { getImageUrl, getAbsUrl } from "../../utilities";

const getBreadcrumbSchema = paths => ({
  "@context": "http://schema.org",
  "@type": "BreadcrumbList",
  itemListElement: [
    {
      "@type": "ListItem",
      position: 1,
      item: {
        "@id": getAbsUrl(),
        name: "Home"
      }
    },
    ...paths.map((p, i) => ({
      "@type": "ListItem",
      position: i + 2,
      item: {
        "@id": p.url,
        name: p.name
      }
    }))
  ]
});

class CommonSEO extends Component {
  render() {
    const {
      title = config.siteTitle,
      description = config.siteDescription,
      metaDescription = true,
      type,
      pageUrl,
      imageUrl = getAbsUrl() + config.siteLogo,
      imageWidth = 1024,
      imageHeight = 1024,
      schemaOrgJSONLD
    } = this.props;

    return (
      <Helmet>
        {/* General tags */}
        {metaDescription ? (
          <meta name="description" content={description} />
        ) : null}
        <meta name="image" content={imageUrl} />

        {/* Schema.org tags */}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>

        {/* Canonical tag */}
        <link rel="canonical" href={pageUrl} />

        {/* OpenGraph tags */}
        <meta property="og:url" content={pageUrl} />
        <meta property="og:title" content={title} />
        {type ? <meta property="og:type" content={type} /> : null}
        <meta property="og:description" content={description} />
        <meta property="og:image" content={imageUrl} />
        <meta property="og:image:width" content={imageWidth} />
        <meta property="og:image:height" content={imageHeight} />
        <meta property="fb:pages" content="351083248278712" />
        {config.siteFBAppID ? (
          <meta property="fb:app_id" content={config.siteFBAppID} />
        ) : null}

        {/* Twitter Card tags */}
        {config.userTwitter ? (
          <meta name="twitter:creator" content={config.userTwitter} />
        ) : null}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:image" content={imageUrl} />
      </Helmet>
    );
  }
}

class PostSEO extends Component {
  render() {
    const {
      ogImageId,
      slug,
      metaDescription,
      abstract,
      author,
      datePublished,
      title,
      alias,
      dateModified,
      subcategory,
      category
    } = this.props.post;

    const imageWidth = 1200;
    const imageHeight = 630;
    const imageUrl = getImageUrl(ogImageId, imageWidth, imageHeight);
    const pageUrl = `${getAbsUrl()}/${slug}/`;
    const description = metaDescription || abstract;
    const breadcrumbList = [
      {
        url: `${getAbsUrl()}/${category.slug}/`,
        name: category.title
      }
    ];

    if (subcategory) {
      breadcrumbList.push({
        url: `${getAbsUrl()}/${category.slug}/${subcategory.slug}/`,
        name: subcategory.title
      });
    }

    const blogPostSchema = {
      "@context": "http://schema.org",
      "@type": "BlogPosting",
      url: pageUrl,
      mainEntityOfPage: {
        "@type": "WebPage",
        "@id": pageUrl
      },
      headline: title,
      author: {
        "@type": "Person",
        name: author
      },
      datePublished,
      dateModified,
      publisher: {
        "@type": "Organization",
        name: config.userName,
        url: getAbsUrl(),
        logo: {
          "@type": "ImageObject",
          url: getAbsUrl() + config.publisherLogo,
          height: 60,
          width: 211
        }
      },
      image: {
        "@type": "ImageObject",
        url: imageUrl
      },
      description
    };

    return (
      <CommonSEO
        title={alias || title}
        description={description}
        type="article"
        pageUrl={pageUrl}
        imageUrl={imageUrl}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        schemaOrgJSONLD={[getBreadcrumbSchema(breadcrumbList), blogPostSchema]}
      />
    );
  }
}

class CategorySEO extends Component {
  render() {
    const { title, abstract, slug, parent, posts = [] } = this.props;
    const breadcrumb = parent
      ? [
          {
            url: `${getAbsUrl()}/${parent.slug}/`,
            name: parent.title
          }
        ]
      : [];
    const pageUrl = parent
      ? `${getAbsUrl()}/${parent.slug}/${slug}/`
      : `${getAbsUrl()}/${slug}/`;
    return (
      <CommonSEO
        title={title}
        description={abstract}
        pageUrl={pageUrl}
        schemaOrgJSONLD={[
          getBreadcrumbSchema(breadcrumb),
          {
            "@context": "http://schema.org",
            "@type": "ItemList",
            itemListElement: posts.map((post, index) => ({
              "@type": "ListItem",
              position: index + 1,
              url: `${getAbsUrl()}/${post.node.slug}/`
            }))
          }
        ]}
      />
    );
  }
}

class HomeSEO extends Component {
  render() {
    return (
      <CommonSEO
        pageUrl={getAbsUrl()}
        type="website"
        schemaOrgJSONLD={[
          {
            "@context": "http://schema.org",
            "@type": "WebSite",
            url: getAbsUrl(),
            name: config.siteTitle,
            alternateName: config.siteTitleAlt ? config.siteTitleAlt : ""
          }
        ]}
      />
    );
  }
}

export default CommonSEO;
export { PostSEO, CategorySEO, HomeSEO, CommonSEO };
